<template>
	<v-data-table
		class="elevation-1"
		:headers="headers"
		:items="value"
		:loading="loading"
		:no-data-text="$t('accounting_firms.nodata.associes')"
		:pagination.sync="paginationSync"
	>
		<template v-slot:items="{ item }">
			<td class="text-xs-center">
				<w-btn v-if="!item.deleted_at" color="" flat icon="edit" mini small @click="openCompanyDetails(item)" />
				<w-layout v-else row>
					<CompanyRestorer :value="item" />
					<VendorDeletor :value="item" />
				</w-layout>
			</td>
			<td v-if="item.pivot && item.pivot.client_code" :class="item.deleted_at ? 'crossed--text error--text' : ''">
				{{ item.company + ' (' + item.pivot.client_code + ')' }}
			</td>
			<td v-else :class="item.deleted_at ? 'crossed--text error--text' : ''">{{ item.company }}</td>
			<td>{{ item.details && item.details.closing_date ? $translateDateTime(item.details.closing_date, 'tiny', true) : '' }}</td>
			<td class="text-xs-center">{{ item.email }}</td>
			<td class="text-xs-center">{{ item.siret }}</td>
			<td class="text-xs-center">{{ item.naf }}</td>
			<td class="text-xs-center">{{ item.details.capital || '' }}</td>
			<td class="text-xs-center">{{ item.details.rcs_city }}</td>
			<td class="text-xs-center">{{ item.details.mission_letter_date }}</td>
			<td class="text-xs-center">{{ item.details.society_type }}</td>
			<td class="text-xs-center">{{ item.details.fiscal_statut }}</td>
			<td class="text-xs-center">{{ item.details.fiscal_type }}</td>
			<td class="text-xs-center">{{ item.details.tva_type }}</td>
			<td class="text-xs-center">{{ item.details.tva_period }}</td>
			<td class="text-xs-center">
				{{ item.details && item.details.fiscal_account == 1 ? $t('actions.yes') : $t('actions.no') }}
			</td>
			<td class="text-xs-center">{{ item.details && item.details.tvts ? $t('actions.yes') : $t('actions.no') }}</td>
			<td class="text-xs-center">
				{{ item.details && item.details.ifu ? $t('actions.yes') : $t('actions.no') }}
			</td>
			<td class="text-xs-center">
				{{ item.details && item.details.das2 ? $t('actions.yes') : $t('actions.no') }}
			</td>
			<td class="text-xs-center">
				{{ item.details && item.details.cfe ? $t('actions.yes') : $t('actions.no') }}
			</td>
			<td class="text-xs-center">
				{{ item.details && item.details.dsi ? $t('actions.yes') : $t('actions.no') }}
			</td>
			<td class="text-xs-center">
				{{ item.details && item.details.irpp ? $t('actions.yes') : $t('actions.no') }}
			</td>
			<td class="text-xs-center">
				{{ item.details && item.details.decloyer ? $t('actions.yes') : $t('actions.no') }}
			</td>
			<td class="text-xs-center">
				{{ item.details && item.details.cvae ? $t('actions.yes') : $t('actions.no') }}
			</td>
		</template>
	</v-data-table>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CustomersListDetailed',
	components: {
		VendorDeletor: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Administration/VendorDeletor')
		}),
		CompanyRestorer: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Administration/CompanyRestorer')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	props: {
		loading: {
			required: false,
			type: Boolean,
			default: () => false
		},
		value: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			headers: [
				{
					text: this.$t('actions.edit'),
					sortable: false
				},
				{
					text: this.$tc('customer.general.company', 1),
					align: 'left',
					value: 'company'
				},
				{ align: 'center', text: this.$t('customer.general.closing_date'), value: 'closing_date' },
				{ text: this.$t('customer.general.contact'), value: 'email' },
				{ text: this.$t('customer.general.siret'), value: 'siret' },
				{ text: this.$t('customer.general.naf'), value: 'naf' },
				{ text: this.$t('customer.general.capital'), value: 'details.capital' },
				{ text: this.$t('customer.general.rcs'), value: 'details.rcs_city' },
				{ text: this.$t('customer.general.mission_letter_date'), value: 'details.mission_letter_date' },
				{ text: this.$t('customer.general.society_type'), value: 'details.society_type' },
				{ text: this.$t('customer.fiscal.fiscal_status'), value: 'details.fiscal_statut' },
				{ text: this.$t('customer.fiscal.fiscal_type'), value: 'details.fiscal_type' },
				{ text: this.$t('customer.fiscal.tva_type'), value: 'details.tva_type' },
				{ text: this.$t('customer.fiscal.tva_period'), value: 'details.tva_period' },
				{ text: this.$t('customer.fiscal.fiscal_account'), value: 'details.fiscal_account' },
				{ text: this.$t('customer.fiscal.tvts'), value: 'details.tvts' },
				{ text: this.$t('customer.fiscal.ifu'), value: 'details.ifu' },
				{ text: this.$t('customer.fiscal.das2'), value: 'details.das2' },
				{ text: this.$t('customer.fiscal.cfe'), value: 'details.cfe' },
				{ text: this.$t('customer.fiscal.dsi'), value: 'details.dsi' },
				{ text: this.$t('customer.fiscal.irpp'), value: 'details.irpp' },
				{ text: this.$t('customer.fiscal.decloyer'), value: 'details.decloyer' },
				{ text: this.$t('customer.fiscal.cvae'), value: 'details.cvae' }
			],
			paginationSync: {
				rowsPerPage: 10,
				totalItems: 10
			}
		}
	},
	computed: {
		// Liste retournée au tableau
		details: function () {
			// Gère les details null
			return this.value.map(company => {
				// Cas Details vide
				if (!company.details) {
					company.details = {}
				}
				// On complète les données des détails avec les données de la Company (Sinon Search et Sort ne fonctionne pas)
				company.details.id = company.id
				company.details.company = company.company
				company.details.siret = company.siret
				company.details.email = company.email
				company.details.naf = company.naf
				return company.details
			})
		}
	},
	methods: {
		openCompanyDetails: function (company = null) {
			this.service.openCompanyDetails(company)
		}
	}
}
</script>
